<template>
  <div class="dynamic">
    <ul class="list">
      <li v-for="item in dynaList" :key="item.id" @click="show(item)">
        <div class="img-box">
          <img :src="item.listImg" />
        </div>
        <p class="p1">{{ item.pOne }}</p>
        <p class="p2">{{ item.pTwo }}</p>
        <p class="p3">
          <img src="../../assets/image/time.png" alt="" />{{ item.pTime }}
        </p>
      </li>
    </ul>

    <a-pagination
      class="pagination"
      v-model="current"
      :total="50"
      show-less-items
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dynaList: [
        {
          id: 1,
          listImg: require("../../assets/image/63.png"),
          pOne: "企业动态1",
          pTwo: "近期，数据库联合吉贝克打造湖仓一体金融行业数据管理平台，实时打破数据孤岛。近期，数据库联合吉贝克打造湖仓...",
          pTime: "2020-08-24",
          path: "newCenterDetails",
        },
        {
          id: 2,
          listImg: require("../../assets/image/63.png"),
          pOne: "企业动态2",
          pTwo: "近期，数据库联合吉贝克打造湖仓一体金融行业数据管理平台，实时打破数据孤岛。近期，数据库联合吉贝克打造湖仓...",
          pTime: "2020-08-24",
          path: "newCenterDetails",
        },
        {
          id: 3,
          listImg: require("../../assets/image/63.png"),
          pOne: "企业动态3",
          pTwo: "近期，数据库联合吉贝克打造湖仓一体金融行业数据管理平台，实时打破数据孤岛。近期，数据库联合吉贝克打造湖仓...",
          pTime: "2020-08-24",
          path: "newCenterDetails",
        },
        {
          id: 4,
          listImg: require("../../assets/image/63.png"),
          pOne: "企业动态4",
          pTwo: "近期，数据库联合吉贝克打造湖仓一体金融行业数据管理平台，实时打破数据孤岛。近期，数据库联合吉贝克打造湖仓...",
          pTime: "2020-08-24",
          path: "newCenterDetails",
        },
        {
          id: 5,
          listImg: require("../../assets/image/63.png"),
          pOne: "企业动态5",
          pTwo: "近期，数据库联合吉贝克打造湖仓一体金融行业数据管理平台，实时打破数据孤岛。近期，数据库联合吉贝克打造湖仓...",
          pTime: "2020-08-24",
          path: "newCenterDetails",
        },
        {
          id: 6,
          listImg: require("../../assets/image/63.png"),
          pOne: "企业动态6",
          pTwo: "近期，数据库联合吉贝克打造湖仓一体金融行业数据管理平台，实时打破数据孤岛。近期，数据库联合吉贝克打造湖仓...",
          pTime: "2020-08-24",
          path: "newCenterDetails",
        },
      ],
      current: 1,
    };
  },
  methods: {
    show(item) {
      // console.log(item);
      this.$router.push({ name: item.path });
    },
  },
};
</script>

<style lang="less">
.dynamic {
  .list {
    width: 1440px;
    margin: 0 auto;
    // text-align: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 33.3%;
      padding: 15px;
      .img-box {
        width: 440px;
        height: 253px;
        overflow: hidden;

        img {
          cursor: pointer;
          transition: 1s transform ease;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .p1 {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ff7216;
        margin-bottom: 0;
      }
      .p2 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-bottom: 0;
      }
      .p3 {
        img {
          width: 12px;
          height: 12px;
          margin-right: 10px;
          margin-bottom: 0px;
        }
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .pagination {
    width: 1440px;
    margin: 50px auto;
  }
}
</style>
